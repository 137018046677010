import React from 'react';
import OrderNowBtn from '../OrderNowBtn';
import Recommendation from '../Recommendation';
import './index.css';

function RecommendationsList(props) {
    const recommendationsList = props.recommendationsList.map((recommendation) => 
        <Recommendation key={recommendation.id} recommendation={recommendation} discount={props.discount} />
    );
    return (
        <section className="recommendation-section">
            <div className="recommendation-container">
                <div className="recommendation-title-container">
                    <h2 className="recommendation-title">
                        Your Designs for Health recommendation includes:
                    </h2>
                </div>        
                <section className="supplement-section">
                    {recommendationsList}                    
                    <section className="recommendation-order-now-btn-section">          
                        <div className="order-now-btn-container">
                            <OrderNowBtn href={props.shopUrl ? `${props.shopUrl}&utm_content=supplements-button` : ''}/>
                        </div>
                    </section>
                </section>
            </div>
        </section>
    )
}


export default RecommendationsList;