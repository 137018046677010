export const timeOfDays = {
    "(as_needed)": "As needed",
    "(bedtime)": "Bedtime",
    "(breakfast)": "Breakfast",
    "(daily_water)": "Daily Water Intake",
    "(dessert)": "Dessert",
    "(dinner)": "Dinner",
    "(lunch)": "Lunch",
    "(mid_afternoon)": "Mid Afternoon",
    "(mid_morning)": "Mid Morning",
    "(snack)": "Snacks",
    "(wakeup)": "Wakeup"
};