import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_AZURE_FUNCTION_URL,
    headers: {
        "Content-Type": "application/json"
    }
});

const newUserSubmission = async submissionId => instance.post("/api/new-user-submission", {id: submissionId})

const Api = {
    newUserSubmission
}

export default Api;