import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Title from './components/Title';
import Subtitle from './components/Subtitle';
import RecommendationsList from './components/RecommendationsList';
import PurchaseNow from './components/PurchaseNow';
import Legend from './components/Legend';
import Footer from './components/Footer';
import Loading from './components/Loading';
import Api from './services/azureFunction';

function App() {
	const [loading, setLoading] = useState(true);
	const [recommendationsList, setRecommendationsList] = useState([]);
	const [discount, setDiscount] = useState(null);
	const [shopUrl, setShopUrl] = useState("");

	const fetchRecommendationsList = async () => {
		const params = new URLSearchParams(window.location.search); 
		const submissionId = params.get('submission_id'); 

		if(submissionId) {
			const result = await Api.newUserSubmission(submissionId);
			const data = result.data.result;
			setRecommendationsList(data.supplements); 
			setDiscount(data.discount); 
			setShopUrl(`${data.shop_url}`); 
		}

		setLoading(false);
	}

	useEffect(() => {	
		fetchRecommendationsList();
	}, []);

	const loadingHtml = loading ?  <Loading /> : <></>

	return (
		<div className={`App ${loading ? 'App-loading' : ''}`}>
			{loadingHtml}
			<Header />
			<Title />
			<Subtitle shopUrl={shopUrl}/>
			<RecommendationsList recommendationsList={recommendationsList} shopUrl={shopUrl} discount={discount}/>
			<PurchaseNow discount={discount} shopUrl={shopUrl}/>
			<Legend />
			<Footer />
		</div>
	);
}

export default App;
