import React from 'react';
import './index.css';

function Title() {
    return (
        <section className="title-section">
            <h1 className="title">CONGRATULATIONS!</h1>
        </section>
    )
}


export default Title;