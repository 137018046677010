import React from 'react';
import './index.css';

function Header() {
    return (
        <section className="header-section">
            <div className="header-img"></div>
        </section>
    )
}


export default Header;