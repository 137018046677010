import React from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import './index.css';
import { timeOfDays } from "../../constants/timeOfDays";

function Recommendation(props) {
    const mapTimeOfDayTag = (timeOfDayTag) => {        
        return timeOfDays[timeOfDayTag]
	}

    const calcDiscount = (price) => {
        return (price - (price * props.discount) / 100).toFixed(2);
    }

    const recommendation = props.recommendation;
    const scheduleList = recommendation.time_of_day.map((timeOfDay) => 
        <p key={timeOfDay} className="supplement-schedule">
            {mapTimeOfDayTag(timeOfDay)}
        </p>
    )

    return (        
        <div className="supplement-container">
            <div className="supplement-img-container">
                <div className="supplement-table-cell">
                    <img className="supplement-img" src={recommendation.image} alt="img"/>
                </div>
            </div>
            <div className="supplement-details-container">
                <div className="supplement-table-cell">
                    <div className="supplement-title-container">
                        <h5 className="supplement-title">
                            {recommendation.title}
                        </h5>
                    </div>
                    <div className="supplement-description-container">
                        <HTMLEllipsis
                            unsafeHTML={recommendation.shortDescription}
                            maxLine='5'
                        />
                    </div>
                </div>
            </div>
            <div className="supplement-scheduling-container">
                <div className="supplement-table-cell">
                    <div className="supplement-dosage-title-container">
                        <h6 className="supplement-dosage-title">
                            DOSE
                        </h6>
                    </div>
                    <div className="supplement-dosage-container">
                        <p className="supplement-dosage">
                            {recommendation.directions}
                        </p>
                    </div>
                    <div className="supplement-schedule-title-container">
                        <h6 className="supplement-schedule-title">
                            SCHEDULE
                        </h6>
                    </div>
                    <div className="supplement-schedule-container">                        
                        {scheduleList}
                    </div>
                </div>
            </div>
            <div className="supplement-price-container">
                <div className="supplement-table-cell">
                    <div className="supplement-discounted-price-container">
                        <h6 className="supplement-discounted-price">
                            {recommendation.price ? `$${calcDiscount(recommendation.price)}`: ""}
                        </h6>
                    </div>
                    <div className="supplement-real-price-container">
                        <h6 className="supplement-real-price">
                            {recommendation.price ? `$${recommendation.price.toFixed(2)}` : ""}
                        </h6>
                    </div>
                </div>
            </div>            
        </div>
    );
}


export default Recommendation;