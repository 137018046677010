import React from 'react';
import './index.css';

function Legend() {
    return (
        <section className="legend-section">
            <div className="legend-container">
                <p className="legend">
                    <em>
                        These statements have not been evaluated by the Food and Drug Administration. 
                        This product is not intended to diagnose, treat, cure, or prevent any disease. 
                        As with any dietary supplement, you should advise your healthcare practitioner of the use of this product.
                    </em>
                </p>
            </div>
      </section>
    )
}


export default Legend;