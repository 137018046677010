import React from 'react';
import OrderNowBtn from '../OrderNowBtn';
import './index.css';

function PurchaseNow(props) {
    return (
        <section className="purchase-now-section">
            <div className="purchase-now-container">
                <div className="purchase-now-legend-container">
                    <div className="purchase-now-title-container">
                        <h3 className="purchase-now-title">
                            Purchase now and get your supplements delivered to your doorstep!
                        </h3>
                    </div>
                    <div className="purchase-now-subtitle-container">
                        { props.discount ? <p className="purchase-now-subtitle"> Order now and get {props.discount}% off your first order! </p> : <></>}                        
                    </div>
                    <div className="purchase-now-button">
                        <OrderNowBtn href={props.shopUrl ? `${props.shopUrl}&utm_content=bottom-button` : ''}/>
                    </div>
                </div>
                <div className="purchase-now-img-container">
                    <img className="purchase-now-img" src="https://wellworld.io/wp-content/uploads/2022/10/pkg.doorstep.above_.crop_-1024x1019.jpg" alt="img"/>
                </div>
            </div>
        </section>
    )
}


export default PurchaseNow;