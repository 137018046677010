import React from 'react';
import OrderNowBtn from '../OrderNowBtn';
import './index.css';

function Subtitle(props) {
    return (
        <section className="subtitle-section">
            <div className="subtitle-container">
                <div className="main-subtitle-container">
                    <h2 className="main-subtitle">
                    YOUR PERSONALIZED SUPPLEMENT RECOMMENDATION IS READY!
                    </h2>
                </div>
                <div className="secondary-subtitle-container">
                    <p className="secondary-subtitle">
                    Thanks for helping us understand your needs better.
                    </p>
                </div>
            </div>
            <section className="order-now-btn-section">          
                <div className="order-now-btn-container">
                    <OrderNowBtn href={props.shopUrl ? `${props.shopUrl}&utm_content=top-button` : ''}/>
                </div>
            </section>
        </section>
    )
}


export default Subtitle;