import React from 'react';
import './index.css';

function OrderNowBtn(props) {
    return (
        <>
            {props.href ? <a className="order-now-btn" target="_blank" href={props.href} rel="noopener noreferrer">ORDER NOW</a> : <></>} 
        </>
    )
}


export default OrderNowBtn;